
import Navigation from "@/components/Navigation.vue";
import RDPLogin from "@/components/RDPLogin.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import InactivityPopup from "@/components/InactivityPopup.vue";
import { mapGetters } from "vuex";
import { sdk } from './app-insights-sdk.js';
import env from "./env.json";

export default {
  components: {
    Navigation,
    RDPLogin,
    LoadingSpinner,
    InactivityPopup
  },
  data () {
    return {
      path: "",
      showSupportBox: false,
      inputPlaceholder: "Please enter support info"
    };
  },
  beforeCreate () {
    const sdkConfig = {
      src: "https://js.monitor.azure.com/scripts/b/ai.3.gbl.min.js",
      // name: "appInsights", // Global SDK Instance name defaults to "appInsights" when not supplied
      // ld: 0, // Defines the load delay (in ms) before attempting to load the sdk. -1 = block page load and add to head. (default) = 0ms load after timeout,
      // useXhr: 1, // Use XHR instead of fetch to report failures (if available),
      // dle: true, // Prevent the SDK from reporting load failure log
      crossOrigin: "anonymous", // When supplied this will add the provided value as the cross origin attribute on the script tag
      // onInit: null, // Once the application insights instance has loaded and initialized this callback function will be called with 1 argument -- the sdk instance (DON'T ADD anything to the sdk.queue -- As they won't get called)
      // sri: false, // Custom optional value to specify whether fetching the snippet from integrity file and do integrity check
      cfg: { // Application Insights Configuration
        connectionString: env.VUE_APP_INSIGHTS_CONN_STR
      }
    };
    sdk(sdkConfig);
  },
  updated () {
    this.$store.commit("setUserName", this.getMsalObj.getActiveAccount().name);
    this.$store.commit("setUserEmail", this.getMsalObj.getActiveAccount().username);
    const notifications = JSON.parse(localStorage.getItem('Notifications'));
    if (Array.isArray(notifications)) {
      this.$store.dispatch("populateNotifications", { notificationArray: notifications });
    }
  },
  methods: {
    toggleShowSupportBox () {
      this.showSupportBox = !this.showSupportBox;
    }
  },
  computed: {
    ...mapGetters(["getFlash", "ready", "getConfig", "getMsalObj", "getIsUserAvailable"]),
    version () {
      return env.VERSION;
    },
    dev_branch () {
      if (Object.prototype.hasOwnProperty.call(env, "DEV_BRANCH")) {
        // eslint-disable-next-line
        return env['DEV_BRANCH'];
      }
      return "";
    },
    isOnStudyListPage: function () {
      if (this.path.includes("/escalation") || this.path.includes("/worklist")) {
        return true;
      }
      return false;
    },
    messageClass () {
      if (this.getFlash.status === "error") {
        return ["alert", "alert-danger"];
      } else if (this.getFlash.status) {
        return ["alert", "alert-success"];
      } else {
        return ["alert", "alert-warning"];
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.path = newValue.path;
      }
    }
  }
};
